<template>
  <div>
    <!-- Media -->
    <b-media class="ml-1">
      <template #aside>
        <b-avatar
          :src="doctorData.avatar"
          :text="avatarText(doctorData.FullName)"
          :variant="`light-${resolveDoctorVariant()}`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1 mt-1">
        {{ doctorData.FullName }}
      </h4>
      <!-- <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline">Update</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
        >
          <span class="d-none d-sm-inline">Remove</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div> -->
    </b-media>

    <!-- Doctor Basic: Input Fields -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onUpdateBasicInfoForDoctor)"
        @reset.prevent="resetForm"
      >
        <!-- Header: Basic Info -->
        <div class="d-flex mt-3 mb-2">
          <feather-icon
            icon="ClipboardIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            Basic Info
          </h4>
        </div>

        <b-row>

          <!-- Field: Full Name -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Full Name"
              rules="required"
            >
              <b-form-group
                label="Name"
                label-for="full-name"
              >
                <b-form-input
                  id="full-name"
                  v-model="doctorData.FullName"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Doctor Specialization -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Doctor Specialization"
              rules="required"
            >
              <b-form-group
                label="Specialization"
                label-for="doctor-specialization"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="doctorData.Specialization.Id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="specializationOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="doctor-specialization"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

          </b-col>

          <!-- Field: Doctor Mobile -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Doctor Mobile"
              rules="required"
            >
              <b-form-group
                label="Mobile"
                label-for="doctor-mobile"
                :state="getValidationState(validationContext)"
              >
                <b-form-input
                  id="mobile"
                  v-model="doctorData.Mobile"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>

        <!-- Action Buttons -->
        <div class="d-flex mt-3">
          <b-button
            variant="primary"
            class="mr-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            type="submit"
          >
             Save Changes
          </b-button>
          <b-button
            variant="outline-secondary"
            type="reset"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="$router.push({ name: 'doctor-list' })"
          >
            Cancel
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
// import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import {
  onUnmounted, computed,
} from '@vue/composition-api'
// Validation
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
// Custome
import store from '@/store'
import doctorStoreModule from '@/store/main/DoctorStoreModule'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    doctorData: {
      type: Object,
      required: true,
    },
    tabIndex: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props, { emit }) {
    const DOCTOR_APP_STORE_MODULE_NAME = 'doctor-store'
    // Register module
    if (!store.hasModule(DOCTOR_APP_STORE_MODULE_NAME)) store.registerModule(DOCTOR_APP_STORE_MODULE_NAME, doctorStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DOCTOR_APP_STORE_MODULE_NAME)) store.unregisterModule(DOCTOR_APP_STORE_MODULE_NAME)
    })

    const resolveDoctorVariant = () => {
      switch (Math.floor(Math.random() * (4 - 0)) + 0) {
        case 0: return 'primary'
        case 1: return 'warning'
        case 2: return 'success'
        case 3: return 'info'
        case 4: return 'danger'
        default: return 'primary'
      }
    }

    const toast = useToast()
    store
      .dispatch('doctor-store/getAllSpecializations')
      .then()
      .catch(() => {
        // console.log('Error fetching Specializations list for dropdown')
      })
    const specializationOptions = computed(() => store.state['doctor-store'].specializations)

    // ? Demo Purpose => Update image on click of update
    // const refInputEl = ref(null)
    // const previewEl = ref(null)
    // const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
    //   // eslint-disable-next-line no-param-reassign
    //   props.doctorData.avatar = base64
    // })

    const resetdoctorData = () => {
      // eslint-disable-next-line no-param-reassign
      props.doctorData.value = JSON.parse(JSON.stringify(props.doctorData.value))
    }

    const onUpdateBasicInfoForDoctor = () => {
      store.dispatch('doctor-store/updateDoctorBasicInfo', {
        Id: props.doctorData.Id,
        FullName: props.doctorData.FullName,
        Mobile: props.doctorData.Mobile,
        Specialization: props.doctorData.Specialization.Id,
      })
        .then(() => {
          emit('update:tabIndex', 1)
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Operation Done',
              icon: 'CheckIcon',
              variant: 'success',
              text: 'You have successfully update doctor!',
            },
          })
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Operation Failed',
              icon: 'XIcon',
              variant: 'danger',
              text: 'Error happened when updating new doctor!',
            },
          })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetdoctorData)

    return {
      resolveDoctorVariant,
      avatarText,
      specializationOptions,
      onUpdateBasicInfoForDoctor,

      //  ? Demo - Update Image on click of update button
      // refInputEl,
      // previewEl,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
