<template>
  <component :is="doctorData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="primary"
      :show="doctorData === undefined"
    >
      <div class="alert-body">
        No doctor selected to edit. Check
        <b-link
          class="alert-link"
          :to="{ name: 'doctor-list'}"
        >
          Doctor List
        </b-link>
        for more doctors.
      </div>
    </b-alert>

    <b-tabs
      v-if="doctorData"
      v-model="tabIndex"
      pills
    >
      <!-- Tab: Basic -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Basic</span>
        </template>
        <doctor-edit-basic-info
          :doctor-data="doctorData"
          :tab-index.sync="tabIndex"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Information</span>
        </template>
        <doctor-edit-details-info
          :doctor-data="doctorData"
          class="mt-2 pt-75"
        />
      </b-tab>

    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import DoctorStoreModule from '@/store/main/DoctorStoreModule'
import DoctorEditBasicInfo from './DoctorEditBasicInfo.vue'
import DoctorEditDetailsInfo from './DoctorEditDetailsInfo.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    DoctorEditBasicInfo,
    DoctorEditDetailsInfo,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'doctor-store'
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, DoctorStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const doctorData = ref(null)
    const tabIndex = ref(1)

    if (router.currentRoute.params.id === undefined || router.currentRoute.params.id === null) {
      doctorData.value = undefined
      return {
        doctorData,
      }
    }

    store.dispatch('doctor-store/getDoctorById', { id: router.currentRoute.params.id })
      .then(response => { doctorData.value = response.data.Data })
      .catch(error => {
        if (error.response.status === 404) {
          doctorData.value = undefined
        }
      })

    return {
      doctorData,
      tabIndex,
    }
  },
}
</script>

<style>

</style>
