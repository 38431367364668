<template>
  <div>

    <!-- Doctor Info: Input Fields -->
    <b-form>
      <!-- Header: Contact Info -->
      <div class="d-flex">
        <feather-icon
          icon="SendIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Contact Info
        </h4>
      </div>

      <b-row class="mt-1">
        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="doctorData.Email"
              type="email"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <!-- Header: Personal Info -->
      <div class="d-flex mt-2">
        <feather-icon
          icon="UserPlusIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Personal Info
        </h4>
      </div>

      <b-row class="mt-1">
        <!-- Field: NationalId -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="National Id"
            label-for="nationalId"
          >
            <b-form-input
              id="nationalId"
              v-model="doctorData.NationalId"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Gender -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Gender"
            label-for="gender"
            label-class="mb-1"
          >
            <b-form-radio-group
              id="gender"
              v-model="doctorData.Gender.Id"
              :options="genderOptions"
              value="GenderName"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Header: Address Info -->
      <div class="d-flex mt-2">
        <feather-icon
          icon="MapPinIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Address Info
        </h4>
      </div>

      <b-row class="mt-1">
        <!-- Field: Address Line -->
        <b-col
          cols="12"
          md="12"
          lg="8"
        >
          <b-form-group
            label="Address Line"
            label-for="address-line"
          >
            <b-form-input
              id="address-line"
              v-model="doctorData.Address"
              dir="auto"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <!-- Field: Country -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Country"
            label-for="country"
          >
            <b-form-input
              id="country"
              v-model="doctorData.Country"
              placeholder=""
            />
          </b-form-group>
        </b-col>

        <!-- Field: City -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="City"
            label-for="city"
          >
            <b-form-input
              id="city"
              v-model="doctorData.City"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Area -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Area"
            label-for="area"
          >
            <b-form-input
              id="area"
              v-model="doctorData.Area"
              placeholder=""
            />
          </b-form-group>
        </b-col>

      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <div class="d-flex mt-3">
      <b-button
        variant="primary"
        class="mr-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="onUpdateDetailsInfoForDoctor(doctorData)"
      >
         Save Changes
      </b-button>
      <b-button
        variant="outline-secondary"
        type="reset"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="$router.push({ name: 'doctor-list' })"
      >
        Cancel
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BFormRadioGroup,
} from 'bootstrap-vue'
import store from '@/store'
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, onUnmounted } from '@vue/composition-api'
import { email } from '@validations'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
// StoreModule
import doctorStoreModule from '@/store/main/DoctorStoreModule'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormRadioGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    doctorData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      email,
    }
  },
  setup(props) {
    const DOCTOR_APP_STORE_MODULE_NAME = 'doctor-store'
    // Register module
    if (!store.hasModule(DOCTOR_APP_STORE_MODULE_NAME)) store.registerModule(DOCTOR_APP_STORE_MODULE_NAME, doctorStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DOCTOR_APP_STORE_MODULE_NAME)) store.unregisterModule(DOCTOR_APP_STORE_MODULE_NAME)
    })

    const resolveDoctorVariant = () => {
      switch (Math.floor(Math.random() * (4 - 0)) + 0) {
        case 0: return 'primary'
        case 1: return 'warning'
        case 2: return 'success'
        case 3: return 'info'
        case 4: return 'danger'
        default: return 'primary'
      }
    }

    const genderOptions = [
      { text: 'Male', value: '1' },
      { text: 'Female', value: '2' },
    ]

    const toast = useToast()
    function onUpdateDetailsInfoForDoctor(doctorData) {
      store.dispatch('doctor-store/updateDoctorDetailsInfo', {
        Id: doctorData.Id,
        NationalId: doctorData.NationalId,
        Gender: doctorData.Gender.Id,
        Email: doctorData.Email,
        Address: doctorData.Address,
        Country: doctorData.Country,
        City: doctorData.City,
        Area: doctorData.Area,
      })
        .then(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Operation Done',
              icon: 'CheckIcon',
              variant: 'success',
              text: 'You have successfully update doctor!',
            },
          })
          this.$router.push({ name: 'doctor-list' })
          // this.$router.go(-1)
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Operation Failed',
              icon: 'XIcon',
              variant: 'danger',
              text: 'Error happened when updating new doctor!',
            },
          })
        })
    }

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.doctorData.avatar = base64
    })

    return {
      resolveDoctorVariant,
      avatarText,
      genderOptions,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,

      // Function
      onUpdateDetailsInfoForDoctor,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
